import { Fragment } from 'react';
import {Helmet} from "react-helmet";
import { Player } from 'video-react';

import './Video.css';

function Video() {
  return (
    <Fragment>
      <Helmet>
        <title>Rich Morris - Making the Magic since 2017</title>
      </Helmet>
      <Player
      className="videoPlayer"
      playsInline
      poster="/assets/poster/20211201.jpg"
      src="/assets/video/20211201.mp4"
      />
    </Fragment>
  );
}

export default Video;
